import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { confirmquitAnimation } from 'src/app/animations/menu.animations';
import { BroadcasterService } from 'src/app/shared/events/broadcaster.service';
import { DataService } from 'src/app/services/data.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-confirmquit',
  templateUrl: './confirmquit.component.html',
	styleUrls: ['./confirmquit.component.scss'],
	animations: [confirmquitAnimation],
})
export class ConfirmquitComponent implements OnInit {
	
	// @HostBinding('@animations') private animations;

  constructor(
		private modalService:ModalsService,
		private broadcast:BroadcasterService,
		private ds:DataService,
		private wordings: WordingsService
	) { }

  ngOnInit() {
	}
	
	
	restart():void
	{
		
		this.broadcast.dispatch('CLICK_RESTART');
		
		/* 
		this.modalService.close('modal')
		.then(() => {
			return this.modalService.close('modal_menu');
		})
		.then(() => {
			return this.modalService.open('home');
		});
		 */
	}
	
	
	resume():void
	{
		this.modalService.close('modal');
	}

}
