import { Component, OnInit, AfterViewInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { environment } from 'src/environments/environment';
import { WordingsService } from 'src/app/services/wordings.service';
import { MesureImpactStateService } from "src/app/services/mesure-impact-state.service";
declare var dmi: any;

@Component({
	selector: 'app-score-send',
	templateUrl: './score-send.component.html',
	styleUrls: ['./score-send.component.scss'],
})
export class ScoreSendComponent implements OnInit, AfterViewInit {
	nickname: string;
	score: number;

	mesureImpactState: number = 0;
	skipped: boolean = false;
	choiceDisplayed: boolean = false;

	formChoiceContainer: HTMLElement;
	scoreSendContainer: HTMLElement;
	bonusPointsContainer: HTMLElement;

	constructor(
		private modalService: ModalsService,
		private api: ApiService,
		public ds: DataService,
		private wordings: WordingsService,
		private userData: UserDataService,
		private impactService: MesureImpactStateService,
	) { }

	ngOnInit() {
		// dmi.resetCookies();
		this.impactService.currentMessage.subscribe(message => this.mesureImpactState = message);
		
		if (dmi.userIsFinished()) this.userData.addMIScore(this.ds.dmiBonusPoints);
		this.score = this.userData.getCurrentTotalScore();

		this.choiceDisplayed = this.ds.versionAllowedForm && this.mesureImpactState == 0 && !dmi.userIsFinished();
	}

	ngAfterViewInit() {
		this.scoreSendContainer = document.getElementById("score_display");
		this.formChoiceContainer = document.getElementById("form_try_container");
		this.bonusPointsContainer = document.getElementById("bonus_feedback");

		this.formChoiceContainer.style.display = this.choiceDisplayed ? "block" : "none";
		this.scoreSendContainer.style.display = !this.choiceDisplayed ? "block" : "none";
		this.bonusPointsContainer.style.display = dmi.userIsFinished() ? "block" : "none";
	}

	valid(): void {
		this.api.saveScore(this.nickname, this.userData.getCurrentTotalScore(), this.userData.data.scores, this.ds.uid, null, null)
			.subscribe(resp => {
				let playerRank: string = '' + resp.data.playerRank;
				this.ds.leaderboard_user = this.ds.getLeaderboardHero(resp.data.leaderboard, playerRank);

				this.ds.leaderboard = this.ds.leaderboard_user;
				let p = this.ds.leaderboard;
				this.modalService.open('leaderboard');
			});
	}

	launchForm(): void {
		this.modalService.open("mesure-impact");
	}

	skipForm(): void {
		this.formChoiceContainer.style.display = "none";
		this.scoreSendContainer.style.display = "block";
	}
}
