import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TimelineMax } from 'gsap'
import { random } from 'gsap/all';

@Component({
  selector: 'app-vhs-button',
  templateUrl: './vhs-button.component.html',
  styleUrls: ['./vhs-button.component.css']
})
export class VhsButtonComponent implements AfterViewInit {

  private squareColors: string[] = ["#edb24a", "d76b3d", "b74c57"];
  @ViewChild("hoverBg") hoverBg: ElementRef;
  @ViewChild("container") container: ElementRef;
  @Input() link: string;
  @Input() text: string;
  @Output() buttonClicked = new EventEmitter();
  timeline: TimelineMax;
  private angle: number;
  private math = Math;

  constructor() { }

  ngAfterViewInit(): void {
    this.timeline = new TimelineMax({paused: true});
    this.timeline.fromTo(this.hoverBg.nativeElement, {opacity: 0}, {opacity: 1, duration: 0.2}); 
    this.timeline.fromTo(this.container.nativeElement, {
      transform: "translate(0, 0)", 
      "-webkit-transform": "translate3d(0,0,0)"
    }, {
      duration: 0.2, 
      transform: "translate(-100px, 0)",
      "-webkit-transform": "translate3d(-100px,0,0)" ,
    }, 0);
    this.angle = Math.random() * 10 - 5;
  }

  onClick(): void {
    this.buttonClicked.emit();
  }

  onMouseEnter(): void {
    this.timeline.timeScale(1);
    this.timeline.resume();
  }

  onMouseLeave(): void {
    this.timeline.timeScale(-1);
    this.timeline.resume();
  }
}
