import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { leaderboardAnimation } from 'src/app/animations/leaderboard.animations';
import { DataService } from 'src/app/services/data.service';
import { WordingsService } from 'src/app/services/wordings.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
	styleUrls: ['./leaderboard.component.scss'],
	animations: [leaderboardAnimation],
})
export class LeaderboardComponent implements OnInit {
	
	
  constructor(
	  	private wordings: WordingsService,
		private modalService:ModalsService,
		private routerEvent:RouterEventService,
		public ds:DataService,
	) { }

  	ngOnInit() {
  	}
  
	valid():void
	{
		this.modalService.isBound('modal_menu') ? this.modalService.open('menu', 'modal_menu') : this.modalService.open('game-end');
	}
}
