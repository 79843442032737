import { Component, OnInit, HostBinding, NgZone, ViewChild, ElementRef, AfterViewInit, AfterContentInit } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';
import { environment } from 'src/environments/environment';
import { WordingsService } from 'src/app/services/wordings.service'
import { TimelineMax } from 'gsap';
import { GameService } from 'src/app/services/game.service';
import { GameAnimationsService } from 'src/app/services/game-animations.service';

@Component({
  selector: 'app-chapter-start',
  templateUrl: './chapter-start.component.html',
	styleUrls: ['./chapter-start.component.scss'],
})
export class ChapterStartComponent implements AfterViewInit, OnInit {
	
	data:{
		title:string,
		name:string,
		characterName: string,
		desc:string,
		img:string,
		gaugeName: string,
		gaugeDesc: string,
	};
	
	styleBG:any;
	chapterStarted:boolean;
	coverBackgroundStyle: any;

	@ViewChild('container') container :ElementRef;
	@ViewChild('characterIcon') characterIcon: ElementRef;

  constructor(
		private wordings: WordingsService,
		private modalsService:ModalsService,
		private userData:UserDataService,
		private sound:SoundPlayerService,
		private gameService: GameService,
		private gameAnimations: GameAnimationsService,
		private ngZone: NgZone,
	) { }

	ngOnInit() {
		let indexScenario:number = this.userData.data.indexScenario;
		let characterGameplayName = this.wordings.translate('ui.chap'+indexScenario+'.character');
		this.data = {
			title: this.wordings.translate('ui.chap'+indexScenario+'.title'),
			name: this.wordings.translate('ui.chap'+indexScenario+'.name'),
			characterName: this.wordings.translate('ui.name.' + characterGameplayName),
			desc: this.wordings.translate('ui.chap'+indexScenario+'.desc'),
			img: 'assets/dynamic/decors/bg_chapter_'+indexScenario+'.png',
			gaugeName: this.wordings.translate('ui.chap'+indexScenario+'.jauge1.title'),
			gaugeDesc: this.wordings.translate('ui.chap'+indexScenario+'.jauge1.text'),
		};
		let nodeId:string = this.userData.data.nodeId;
		this.chapterStarted = (nodeId != null && nodeId != '');

		const characterName = this.wordings.translate("ui.chap" + indexScenario + ".character");
	}

	ngAfterViewInit() {
		
		let indexScenario:number = this.userData.data.indexScenario;

		const characterName = this.wordings.translate("ui.chap" + indexScenario + ".character");
		this.characterIcon.nativeElement.style['background-image'] = 'url(assets/dynamic/persos/icon_' + characterName + '.png)';
		
		setTimeout(() => {
			this.sound.play('assets/sounds/jaccepte_woosh.mp3');
			
		}, 920);

		const timeline = new TimelineMax();
		timeline.fromTo(this.container.nativeElement, {opacity: 0}, {opacity: 1, duration: 1});
		
		
		
		timeline.add(this.gameService.setBackground('bg_chapter_' + indexScenario, false), 0);

		this.coverBackgroundStyle = {
			'background-image': 'url(assets/images/pages/chapter_start/cover1.png)'
		};
		
		
		//console.log("chapter started ? " + this.chapterStarted + '(nodeId : ' + nodeId + ')');
		// if(environment.debug) this.chapterStarted = true;
		
		this.userData.save();
		this.gameAnimations.startUpdateAnimation();
		
	}
	
	switchToGame(): void {

		const timeline = new TimelineMax();
		timeline.set(this.container.nativeElement, {'pointer-events': 'none'});
		timeline.to(this.container.nativeElement, {opacity: 0, duration: 1});
		timeline.call(() => this.ngZone.run(() => this.modalsService.open('game')));
	}
	
	clickStart():void
	{
		let copy = {...this.userData.data.dataActions};
		//console.log({...copy});
		this.userData.data.chapterStartData = copy;
		//console.log(this.userData.data);
		this.switchToGame();
	}
	
	clickResume():void
	{
		this.switchToGame();
	}
	
	clickRestart():void
	{
		this.userData.resetChapter();
		this.userData.save();
		this.switchToGame();
	}
}
