export enum NodeType{
	Start = 'Start',
	Background = 'Background',
	Inventory = 'Inventory',
	Dialogue_Begin = 'Dialogue_Begin',
	DialogElement = 'DialogElement',
	Dialogue_End = 'Dialogue_End',
	Ellipse_Begin = 'Ellipse_Begin',
	Ellipse_End = 'Ellipse_End',
	End = 'End'	,
}


export enum GameMode{
	Dialog = 'Dialog',
	Ellipse = 'Ellipse',
}


export enum CallbackType{
	Dialog = 'Dialog',
	Ellipse = 'Ellipse',
	Didascalie = 'Didascalie',
	Background = 'Background',
	End = 'End',
}