export interface UserData{
	
	logs:LogItem[],
	
	nodeId:string,
	indexScenario:number,
	chapterStartData: any;
	dataActions:any,
	bg:string,
	scores: number[],
	
	ngGameover:number,
};

export interface LogItem{
	talker:string,
	msg:string,
	isDidascalie?:boolean,
}



export function createMock(data:any = null):UserData{
	let output:UserData = {
		
		logs:[],
		nodeId:null,
		indexScenario:0,
		dataActions:{},
		chapterStartData: {},
		bg:null,
		ngGameover:0,
		scores: [],
	};
	output = {...output, ...data};
	return output;
};