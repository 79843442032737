import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, NgZone, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import {TimelineMax} from 'gsap';
import { UserData } from 'src/app/model/userdata.interface';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { GoogleAnalyticsService } from 'src/app/shared/api/google-analytics.service';

@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss']
})
export class AnswersComponent implements AfterViewInit {
	private displayAnswers: string[];
	@ViewChild('mainElement') mainElement:ElementRef;
	@ViewChild('characterIcon') characterIcon: ElementRef;
	@ViewChildren("answers") answerElements: QueryList<ElementRef>;
	@Output() answerClicked: EventEmitter<number> = new EventEmitter<number>();
	private isVisible: boolean;
	private pendingDisplayAnswers: string[];

	constructor(
		private ngZone: NgZone,
		private animations : GameAnimationsService,
		private host: ElementRef, 
		private userData: UserDataService,
		private wordings: WordingsService,
		private ga: GoogleAnalyticsService,
	) { }

	ngAfterViewInit() {
		const scenarioIndex = this.userData.data.indexScenario;
		const characterName = this.wordings.translate("ui.chap" + scenarioIndex + ".character");
		this.characterIcon.nativeElement.style['background-image'] = 'url(assets/dynamic/persos/icon_' + characterName + '.png)';
		
	}

	showAnswers(answers: string[])
	{
		this.pendingDisplayAnswers = answers;
		//if(this.isVisible)
		//	this.animations.pushAnimation("Disappear answers", this.getDisappearAnimation(), 0, 0);
		if(answers.length > 1 && this.userData.data.indexScenario > 0)
			this.animations.pushAnimation("Appear answers", this.getAppearAnimation(), 2, 15);
		this.isVisible = answers.length > 1 && this.userData.data.indexScenario > 0;
	}

	answerHilightAnimation(index: number, targetColor: string)
	{
		let timeline = new TimelineMax();
		for(let i=0; i<this.answerElements.length; i++)
		{
			if(i != index)
			{
				timeline.to(this.answerElements.get(i).nativeElement,
				{
					opacity: 0,
					duration: 0.5,
				}, 0);
			}
			else if(targetColor != null)
			{
				timeline.to(this.answerElements.get(i).nativeElement,
				{
					color: targetColor,
					duration: 0.5,
				}, 0);
			}
		}
		timeline.to(this.answerElements.get(index).nativeElement,
		{
			opacity: 0,
			duration: 0.5,
		}, '+=0.5');
		timeline.add(this.getDisappearAnimation(), '-=0.2');
		return timeline;
	}

	clickAnswer(index:number):void
	{
		// this.ga.emitEvent('answer', 'answer', 'q' + (this.index + 1) + '_a' + (this.selectedAnswer));
		this.answerClicked.emit(index);
	}

	getAppearAnimation(): any
	{
		let timeline = new TimelineMax();
		
		timeline.call(() => {
			this.ngZone.run(() => {
				this.displayAnswers = this.pendingDisplayAnswers;
			})
		});
		timeline.set(this.host.nativeElement, {'pointer-events': 'none'})
		timeline.to(this.host.nativeElement, 
			{
				opacity: 1,
				duration: 0.2, 
			}
		);
		timeline.set(this.host.nativeElement, {'pointer-events': 'auto'})
		for(let i=0; i<this.answerElements.length; i++)
		{
			timeline.set(this.answerElements.get(i).nativeElement, { 'opacity': 1});
		}
		//console.log(timeline);
		return timeline;
	}

	getDisappearAnimation(): any
	{
		let timeline = new TimelineMax();
		this.host.nativeElement.style['pointer-events'] = 'none';

		timeline.set(this.host.nativeElement, {'pointer-events': 'none'});
		timeline.to(this.host.nativeElement,
			{
				opacity: 0,
				duration: 0.4,
			}
		);
		return timeline;
	};
}
