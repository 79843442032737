import { Component, OnInit, HostBinding, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { FullscreenService } from 'src/app/shared/utils/fullscreen.service';
import { PlatformService } from 'src/app/shared/utils/platform.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { AnimateBase } from 'src/app/shared/animation/animate.base.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { TimelineMax } from 'gsap';
import { GameService } from 'src/app/services/game.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
	
	constructor(
		private ds: DataService,
		private wordings: WordingsService,
		private modalsService: ModalsService,
		private animate: TweenAnimateService,
		private navigation: NavigationService,
		private ngZone: NgZone,
		private gameService: GameService,
		private fs: FullscreenService,
		private rootElement:ElementRef
	) { }

	ngAfterViewInit() {
		const timeline = new TimelineMax();
		timeline.fromTo(this.rootElement.nativeElement, {opacity: 0}, {opacity: 1, duration: 1});
		this.gameService.hideBackgroundWithFade();
	}

	clickPlay(): void {
		const timeline = new TimelineMax();
		timeline.fromTo(this.rootElement.nativeElement, {opacity: 1}, {opacity: 0, duration: 1});
		timeline.call(() => {
			this.ngZone.run(() => {
				if(this.ds.hasLogin)
					this.modalsService.open("login");
				else this.navigation.startGame();
			});
		});
		this.fs.openFullscreen();
	}
}
