import { Component, OnInit, Input, ViewChild, ElementRef, NgZone, Output, EventEmitter } from '@angular/core';
import { AnimData, GameAnimationsService } from 'src/app/services/game-animations.service';
import {TweenMax, TimelineMax} from "gsap";
import { TextLineData } from 'src/app/model/callbackinfo.interface';
import { WordingsService } from 'src/app/services/wordings.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { DataService } from '../../services/data.service';
import { UserDataService } from '../../services/user-data.service';

@Component({
  selector: 'app-thank',
  templateUrl: './thank.component.html',
  styleUrls: ['./thank.component.scss']
})
export class ThankComponent implements OnInit {
  
  @ViewChild('mainElement') mainElement:ElementRef;

  constructor(private modalService: ModalsService, private ngZone: NgZone, private wordings: WordingsService, private ds: DataService, private userData: UserDataService) {
  }

  ngOnInit() {
  }



  nextClicked() {
    new TimelineMax().to(this.mainElement.nativeElement, {opacity: 0, duration: 0.5})
    .call(() => this.ngZone.run(() => this.modalService.open('game-end')));
  }
}
