import { CallbackType } from "./types";

export interface TextLineData {
	text: string;
	parameters: string[];
	gaEventName: string,
};

export interface CallbackInfo{
	
	continue:boolean,
	answers:string[],
	actions:string[][],
	callback:boolean,
	background:string,
	talkers:string[],
	talkerName:string,
	nodeId:string,
	voiceFile?:string,		//analytics
	textLines: TextLineData[] // There can be multiple text lines at once, of different types (mainly Didascalie + basic text line)
};


export function createMock(data:any = null):CallbackInfo{
	let output:CallbackInfo = {
		continue:true,
		answers:[],
		actions:[],
		callback: true,
		background:null,
		talkers:[],
		talkerName:null,
		nodeId:null,
		textLines: []
	};
	output = {...output, ...data};
	return output;
};