import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChange, SimpleChanges, ApplicationRef, NgZone } from '@angular/core';
import * as ProgressBar from 'progressbar.js';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import { GameService } from 'src/app/services/game.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { TimelineMax, Power1 } from 'gsap';
import utils from "gsap"
import { analyzeNgModules } from '@angular/compiler';

@Component({
  selector: 'app-jauge',
  templateUrl: './jauge.component.html',
  styleUrls: ['./jauge.component.scss']
})
export class JaugeComponent implements OnInit {
	@Input() radius: number = 50;
	@Input() stroke: number = 10;
	@Input() defaultColor: string = 'white'; 
	@Input() bonusColor: string = 'white';
	@Input() malusColor: string = 'white';
	@Input() startValue: number = -1;

	@ViewChild('rotator_right') rotatorRight: ElementRef;
	@ViewChild('rotator_left') rotatorLeft: ElementRef;
	@ViewChild('circle_right') circleRight: ElementRef;
	@ViewChild('circle_left') circleLeft: ElementRef;
	@ViewChild('value') public valueElement: ElementRef;

	private circumference: number;
	private currentValue: number = 50;
	private math = Math;

	private pathStyle = {};

  	constructor(
		private app:ApplicationRef,
		private gameService: GameService,
		private gameAnimations: GameAnimationsService,
		private userData: UserDataService,
		private ngZone: NgZone
	) { }

	updateDisplay() {
	}

  	ngOnInit() {
		this.pathStyle = {
			'stroke-dashoffset': '' + this.circumference,
			'stroke-width': this.stroke + 'px',
		};
		if(this.startValue < 0)
			this.currentValue = this.userData.data.dataActions["jauge1"];
		else
			this.currentValue = this.startValue;
	}

	setValue(value: number, colorRatio: number, barAlpha: number = 1) {
		let angle = value / 100 * 360;
		let color: string;
		if(colorRatio >= 0)
		{
			color = utils.utils.interpolate(this.defaultColor, this.bonusColor, colorRatio);

		}
		else
		{
			color = utils.utils.interpolate(this.defaultColor, this.malusColor, -colorRatio);
		}
		this.circleRight.nativeElement.style['border-color'] = 'rgba(255, 255, 255, ' + barAlpha + ')';
		this.circleLeft.nativeElement.style['border-color'] = 'rgba(255, 255, 255, ' + barAlpha + ')';
		this.valueElement.nativeElement.style['color'] = color;
		if(angle < 180)
		{
			this.rotatorRight.nativeElement.style.transform = 'rotate(' + angle + 'deg)';
			this.rotatorLeft.nativeElement.style.transform = 'rotate(' + 0 + 'deg)';
		}
		else
		{
			this.rotatorRight.nativeElement.style.transform = 'rotate(' + 180 + 'deg)';
			this.rotatorLeft.nativeElement.style.transform = 'rotate(' + (-180 + angle) + 'deg)';
		}
		this.ngZone.run(() => {
			this.currentValue = value;
		});
	}
	  
}
