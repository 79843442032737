import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { WordingsService } from 'src/app/services/wordings.service';
import { TimelineMax } from 'gsap';
import { NavigationService } from 'src/app/shared/navigation/navigation.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  @ViewChild('container') container: ElementRef;

  constructor(private wordings: WordingsService, private ngZone: NgZone, private modalService: ModalsService) { }

  ngOnInit() {
  }

  selectLanguage(languageIndex: number) {
    this.wordings.selectLanguage(languageIndex);
    new TimelineMax().fromTo(this.container.nativeElement, 1, {opacity: 1}, {opacity: 0})
    .call( () => this.ngZone.run(() => this.modalService.open('home')));
  }
}
