import { UserDataService } from 'src/app/services/user-data.service';
import { NavigationService } from './../../services/navigation.service';
import { Component, OnInit, HostBinding, ApplicationRef, Renderer2, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {TimelineMax, Back, Power1} from "gsap";
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { environment } from 'src/environments/environment';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { JaugeComponent } from '../page-game/widgets/jauge/jauge.component';
import { DEFAULT_INTERPOLATION_CONFIG } from '@angular/compiler';
import { GameService } from 'src/app/services/game.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-chapter-end',
  templateUrl: './chapter-end.component.html',
	styleUrls: ['./chapter-end.component.scss'],
})
export class ChapterEndComponent implements OnInit, AfterViewInit {
	
	//@HostBinding('@animations') private animations;
	@ViewChild('score_minus') elmtScoreMinus:ElementRef;
	@ViewChild('title') titleText:ElementRef;
	@ViewChild('score_value') scoreValueElement:ElementRef;
	@ViewChild('score_text') scoreTextElement:ElementRef;
	@ViewChild('separator') separatorElement:ElementRef;
	@ViewChild('continue_button') continueButton:ElementRef;
	@ViewChild('jauge1') jauge1: JaugeComponent;
	
	
	score: number = 1000;
	numChapter:number = 3;
	
	scoreMinus:number;
	math = Math;
	jauge1Value: number;
	indexScenario: number;
	
  constructor(
		public ds:DataService,
		public wordings: WordingsService,
		private modalService:ModalsService,
		private timeout:TimeoutService,
		private navigation:NavigationService,
		private userData:UserDataService,
		private animate:TweenAnimateService,
		private app:ApplicationRef,
		private renderer:Renderer2,
		private changeDetector: ChangeDetectorRef,
		private gameService: GameService,
		private api: ApiService,
	) { }

	ngOnInit() {
		this.gameService.hideBackgroundWithFade();
		let ptsGameover:number = +this.ds.params.score.pts_per_gameover;
		
		let nbGameover:number = this.userData.data.ngGameover;
		if(environment.debug) nbGameover = 1;
		
		let lapsTimeGO:number = 0.7;
		let timeAnimGameover:number = nbGameover * lapsTimeGO;
		this.scoreMinus = ptsGameover;
		
		this.userData.data.ngGameover = 0;
		this.userData.save();

		this.indexScenario = this.userData.data.indexScenario;

		let score:number = this.userData.getChapterTotalScore(this.userData.data.indexScenario);
		let scoreType:number = this.userData.data.indexScenario - 1;
		
		this.api.getIntermediateLeaderboard(this.ds.uid, score, scoreType)
		.subscribe(resp => {
			//leaderboard_global
			let playerRank:string = resp.data.playerRank;
			this.ds.leaderboard_endchapter = this.ds.getLeaderboardHero(resp.data.leaderboard, playerRank);
			
			let indexHero:number;
			this.ds.leaderboard_endchapter.forEach((rank, index) => {
				if(rank.rank == playerRank) indexHero = index;
			});
			
			//pour l'instant : afficher 3 premiere lignes
			this.ds.leaderboard_endchapter = this.ds.leaderboard_endchapter.slice(Math.max(0, indexHero - 1), Math.max(0, indexHero - 1) + 3);
			if(indexHero == 0)
			{
				this.ds.leaderboard_endchapter[0].isHero = true;
			}
			else if(this.ds.leaderboard_endchapter[1]){
				this.ds.leaderboard_endchapter[1].isHero = true;
			}
			
			
		});
	}
	
	ngAfterViewInit() {
		
		this.appearAnimation();
		this.gameService.hideBackgroundWithFade();
	}

	appearAnimation(): void
	{
		let timeline = new TimelineMax();
		timeline.fromTo(this.titleText.nativeElement, {opacity: 0}, {duration: 0.7, opacity: 1}, 0.3);
		timeline.fromTo(
			this.separatorElement.nativeElement, 
			{
				opacity: 0, 
				transform: 'scale(0)'
			}, 
			{
				ease: Back.easeOut.config(1.7), 
				opacity: 1, 
				transform: 'scale(1)',
				duration: 0.5,
			}, '-= 0.6');
		timeline.fromTo(
			this.scoreTextElement.nativeElement,
			{
				opacity: 0, 
				transform: 'translate(-50%, 50px)'
			}, 
			{
				ease: Back.easeOut.config(3), 
				opacity: 1,
				transform: 'initial',
				duration: 0.4,
			},
			'-=0.1'
		);
		timeline.fromTo(
			this.scoreValueElement.nativeElement,
			{
				opacity: 0,
				transform: 'translate(-50%, 50px)'
			}, 
			{
				ease: Back.easeOut.config(1.7),
				opacity: 1,
				transform: 'initial',
				duration: 0.4,
			},
			'-=0.1'
		);
		timeline.fromTo(
			this,
			{
				score: this.userData.getChapterTotalScore(this.userData.data.indexScenario - 1),
			}, 
			{
				score: this.userData.getChapterTotalScore(this.userData.data.indexScenario),
				duration: 1,
				onUpdate: () => {this.changeDetector.detectChanges()},
			}
		);
		timeline.fromTo(
			this.continueButton.nativeElement,
			{
				opacity: 0,
				transform: 'translate(-50%, 50px)'
			}, 
			{
				ease: Back.easeOut.config(1.7),
				opacity: 1,
				transform: 'translate(-50%, 0)',
				duration: 0.4,
			},
			'+=0.5'
		);

		timeline.to(
			this, {
				jauge1Value: this.userData.data.dataActions["jauge1"],
				ease: Power1.easeInOut,
				duration: 1,
				onUpdate: () => {
					if(this.jauge1 != undefined)
						this.jauge1.setValue(this.jauge1Value, 0);
				}
			}, 1
		);
	}
	
	
	clickContinue():void
	{
		this.userData.incrChapter();
		let indexScenario:number = this.userData.data.indexScenario;
		if(indexScenario + 1 < this.ds.scenarios.length) // last chapter is outro, so quiz instead of chapter end
		{
			this.modalService.open('chapter-start');
		}
		else
		{
			this.modalService.open('game');
		}
	}
}
