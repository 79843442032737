import { Component, OnInit, Input, ViewChild, ElementRef, Output, NgZone, QueryList, ViewChildren } from '@angular/core';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import {TweenMax, TimelineMax, Power0, Power1} from "gsap";
import { AnimData, GameAnimationsService } from 'src/app/services/game-animations.service';
import { UserDataService } from 'src/app/services/user-data.service';

enum TalkerUpdateAction
{
  None, Change, Appear, Disappear
}

export class Talker
{
  name: string;
  emote: string;
}

@Component({
  selector: 'app-talkers',
  templateUrl: './talkers.component.html',
  styleUrls: ['./talkers.component.scss']
})
export class TalkersComponent implements OnInit {
  @Output() animationPending: boolean;
  
  @ViewChildren("talkers") talkerElements: QueryList<ElementRef>;
  
  private talkers: Talker[] = [null, null];
  private activeTalkerIndex: number = -1;

  constructor(private animations:GameAnimationsService, private ngZone: NgZone, private userData: UserDataService) {}

  setTalkers(newTalkers: Talker[]): void {
    if(newTalkers.length == 0 && this.talkers.length == 0)
      return;
    let talkersToAdd: Talker[] = [];
    let talkersToAddActive: boolean[] = [];
    let talkersToRemove: number[] = [];
    let nextActiveTalkerIndex = this.activeTalkerIndex;
    // Check if characters already present
      let pushAnim = false;
      let timeline = new TimelineMax();
    timeline.add("talkerActivate");
    for(let i=0; i<newTalkers.length; i++)
    {
      if(newTalkers[i].emote == 'empty')
        continue;
      for(let j=0; j<this.talkers.length; j++)
      {
        if(this.talkers[j] != null && newTalkers[i] != null && newTalkers[i].name == this.talkers[j].name)
        {
          let changeEmote = this.talkers[j].emote != newTalkers[i].emote;
          if(changeEmote)
          {
            timeline.add(this.talkerChangeEmote(j, newTalkers[i]));
            pushAnim = true;
            this.talkers[j] = newTalkers[i];
          }
        }
      }
    }
    for(let i=0; i<newTalkers.length; i++)
    {
      let talkerPresent = false;
      if(newTalkers[i].emote == 'empty')
        continue;
      for(let j=0; j<this.talkers.length; j++)
      {
        if(this.talkers[j] != null && newTalkers[i] != null && newTalkers[i].name == this.talkers[j].name)
        {
          talkerPresent = true;
        }
      }
      for(let j=0; j<this.talkers.length; j++)
      {
        if(this.talkers[j] != null && newTalkers[i] != null && newTalkers[i].name == this.talkers[j].name)
        {
          // talker already present
          if(i == 0 && this.activeTalkerIndex != j)
          {
            // already present but must become active talker
            timeline.add(this.talkerSetActive(j, true), "talkerActivate");
            pushAnim = true;
            nextActiveTalkerIndex = j;
          }
          else if(i != 0 && this.activeTalkerIndex == j)
          {
            // already present but must become inactive
            timeline.add(this.talkerSetActive(j, false), "talkerActivate");
            pushAnim = true;
          }
          break;
        }
      }
      if(!talkerPresent)
      {
        // talker not present, must find slot
        talkersToAdd.push(newTalkers[i]);
        talkersToAddActive.push(i == 0);
      }
    }
    this.activeTalkerIndex = nextActiveTalkerIndex;

    // find slots for new talkers
    for(let i=0; i<this.talkers.length; i++)
    {
      if(this.talkers[i] == undefined || this.talkers[i] == null)
      {
        talkersToRemove.push(i);
        this.talkers[i] = null;
      }
      else if(!this.hasTalker(newTalkers, this.talkers[i].name))
      {
        talkersToRemove.push(i);
        if(this.talkers[i].emote != 'empty')
          this.animations.pushAnimation('talker hide ' + i, this.talkerHide(i), 0, 20); 
        this.talkers[i] = null;
      }
    }
    for(let i=0; i<talkersToRemove.length; i++)
    {
      let emptySlot = talkersToRemove[i];
      if(talkersToAdd[i] !== undefined)
      {
        timeline.add(this.talkerShow(emptySlot, talkersToAdd[i], talkersToAddActive[i]), "talkerActivate");
        pushAnim = true;
      }
      if(talkersToAddActive[i])
        this.activeTalkerIndex = emptySlot;
      this.talkers[emptySlot] = talkersToAdd[i];
    }
    if(pushAnim)
      this.animations.pushAnimation('talker activation change', timeline, 2, 10);
  }

  hasTalker(talkers: Talker[], talkerName: string): boolean
  {
    for(let talker of talkers)
    {
      if(talker.name == talkerName)
        return true;
    }
    return false;
  }

  ngOnInit() {
  }

  talkerShow(index: number, talker: Talker, activeTalker: boolean) : any {
    let timeline = new TimelineMax();
    let talker_image = 'url("assets/dynamic/persos/' + talker.name + "_" + talker.emote + '.png")';
    if(talker.emote == 'phone')
    {
      talker_image = 'url("assets/dynamic/persos/phone' + this.userData.data.indexScenario + '.png")';
    }
    timeline.set(this.talkerElements.get(index).nativeElement,
    {
      'background-image': talker_image,
      opacity: 0,
      transform: 'translate(200px, 0)',
      filter: activeTalker ? 'none' : 'grayscale(60%)',
    });
    timeline.to(this.talkerElements.get(index).nativeElement, 
      {
        opacity: 1,
        transform: activeTalker ? '' : 'scale(0.95)',
        right: activeTalker ? '145px' : '-80px',
        filter: activeTalker ? 'none' : 'grayscale(60%)',
        duration: 0.5
      }
    );
    return timeline;
  }

  talkerChangeEmote(index: number, talker: Talker): any
  {
    let timeline = new TimelineMax();
    timeline.to(this.talkerElements.get(index).nativeElement, 
      {
        opacity: 0,
        duration: 0.5
      }
    );
    timeline.set(this.talkerElements.get(index).nativeElement,
    {
      'background-image': 'url("assets/dynamic/persos/' + talker.name + "_" + talker.emote + '.png")',
    });
    timeline.to(this.talkerElements.get(index).nativeElement, 
      {
        opacity: 1,
        duration: 0.5
      }
    );
    return timeline;
  }

  talkerHide(index: number) : any
  {
    let timeline = new TimelineMax();
    timeline.to(this.talkerElements.get(index).nativeElement, 
      {
        opacity: 0,
        duration: 0.3
      }
    );
    return timeline;
  }

  talkerSetActive(index: number, activeTalker: boolean) : any
  {
    let talkerElement = this.talkerElements.get(index).nativeElement;
    let timeline = new TimelineMax();
    timeline.to(
      talkerElement, 
      {
        filter: activeTalker ? 'none' : 'grayscale(40%)',
        transform: activeTalker ? '' : 'scale(0.95)',
        ease: Power0.easeNone,
        duration: 0.6,
      }).to(
        talkerElement, 
        {
          right: activeTalker ? '-110px' : '170px',
          ease: Power1.easeOut,
          duration: 0.3,
        }, 0)
      .to(
        talkerElement, 
        {
          right: activeTalker ? '145px' : '-80px',
          ease: Power1.easeInOut,
          duration: 0.7,
        }, 0.3)
      .set(
        talkerElement,
        {
          'z-index': activeTalker ? 0 : -1,
        },
        0.15,
      );
      return timeline;
  }
}
