import { ChapterItem } from './../model/chapteritem.interface';
import { Company } from './../model/company.interface';
import { QuizItem } from './../model/quizitem.interface';
import { Graph } from './../model/gamenode.interface';
import { Injectable } from '@angular/core';
import { scaledAnchorProp, scaledOptions } from '../shared/directives/scaled.directive';
import { Rank } from '../model/rank.interface';

@Injectable({
	providedIn: 'root'
})
export class DataService {

	uiMessage: string;

	public uid: string;
	opentutoMenu: boolean;

	company: Company;
	scenarios: ChapterItem[];

	textDidascalie: string;
	modeClient: boolean;
	params: any;

	quiz: QuizItem[];
	leaderboard: Rank[];
	leaderboard_global: Rank[];
	leaderboard_user: Rank[];
	leaderboard_endchapter: Rank[];

	modeConnexion: boolean;
	hasLogin: boolean = false;

	scaledOptions: scaledOptions = {
		mode: 'contain',
		uiWidth: 1200,
		uiHeight: 675,
		anchor: scaledAnchorProp.CENTER,
	};

	projectId: string = "b34ac66a74ecc2df1dd7ccb67f235cbb";
	dmiEndpointUrl: string = "https://impact.dowino.com/service/";
	dmiBonusPoints: number = 1200;
	dmiFormNum: number = 1;
	versionUIDsAllowedForms: string[] = ["dowino"];
	versionAllowedForm: boolean = false;

	scaledEnabled: boolean = true;


	getLeaderboardHero(leaderboard: Rank[], playerRank: string): Rank[] {
		//set hero where appropriate
		for (let k in leaderboard) {
			;
			let item = leaderboard[k];
			if (item.rank == playerRank) item.isHero = true;
		}
		return leaderboard;
	}

	constructor() { }
}
