import { Injectable } from '@angular/core';
import {TimelineMax } from 'gsap'

export enum AnimChainMode {
  Default,
  WaitEnd,
}

export interface AnimData {
  animationStep: number;
  priority: number;
  timeline: any;
  debugName: string;
  chainMode: AnimChainMode;
  delay: number;
};

@Injectable({
  providedIn: 'root'
})
export class GameAnimationsService {
  pendingAnimations: AnimData[] = [];
  cursor: number = 0;
  currentTimeline: any;
  timelineStack: any[] = [];
  public animationPlaying: boolean = false;
  constructor() { }

  pushAnimation(debugName: string, timeline: any, animationStep: number, priority: number, chainMode: AnimChainMode = AnimChainMode.Default, delay: number = 0)
  {
    //console.log("Push Animation " + debugName + " for step " + animationStep + " with prio " + priority + ' | ' + timeline);
    // timeline.call(() => {console.log("START ANIM " + debugName);}, [], 0);
    // timeline.call(() => {console.log("FINISH ANIM " + debugName);}, [], "+=0");
    this.pendingAnimations.push({animationStep: animationStep, priority: priority, timeline: timeline, debugName: debugName, chainMode: chainMode, delay: delay});
  }

  startUpdateAnimation()
  {
    //console.log(this.pendingAnimations);
    if(this.pendingAnimations.length === 0)
      return;
    this.pendingAnimations.sort((a, b) => {
      if(a.animationStep !== b.animationStep)
        return a.animationStep - b.animationStep;
      else
        return a.priority - b.priority
    });
    //console.log(this.pendingAnimations);
    let timeline = new TimelineMax({onComplete: () => this.onTimelineFinished()});
    timeline.call(() => {
      this.animationPlaying = true;
    });
    let lastAnimStep = -1;
    let stepAnimIndex = 0;
    let timelineCursor = 0;
    for(const anim of this.pendingAnimations)
    {
      if(anim.timeline) {
        if(lastAnimStep === anim.animationStep)
        {
          stepAnimIndex += 1;
        }
        else {
          timelineCursor = 0;
          stepAnimIndex = 0;
          lastAnimStep = anim.animationStep;
          timeline.addLabel("step" + lastAnimStep);
        }
        
        const animLabel = "step" + lastAnimStep + "+=" + timelineCursor;
        timelineCursor += anim.delay > 0 ? anim.delay : 0.2;
        timeline.add(anim.timeline, animLabel);
      }
    }
    timeline.call(() => {
      this.animationPlaying = false;
    });
    this.pendingAnimations = [];
    if(this.currentTimeline)
      this.timelineStack.push(timeline);
    else
    { 
      this.currentTimeline = timeline;
      // this.currentTimeline.timeScale(0.3);
      //console.log("PLAY DIRECTLY");
      timeline.play();
    }
  }

  onTimelineFinished()
  {
    if(this.timelineStack.length > 0)
    {
      this.currentTimeline = this.timelineStack.pop();
      this.currentTimeline.play();
    }
    else 
      this.currentTimeline = null;
  }
}
