import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, NgZone, Input, AfterViewInit } from '@angular/core';
import { AnswersComponent } from '../answers/answers.component';
import { TextLineData } from 'src/app/model/callbackinfo.interface';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import { TimelineMax } from 'gsap';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserData } from 'src/app/model/userdata.interface';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-bubble-self',
  templateUrl: './bubble-self.component.html',
  styleUrls: ['./bubble-self.component.scss']
})
export class BubbleSelfComponent implements AfterViewInit {
  private currentText: string;
  private currentVisibility;

  private targetVisibility: boolean;
  private targetText: any;
  private showAnswers: boolean = false;
  private blockInput: boolean = true;
  private ptrEvents: string;
  @ViewChild('mainElement') mainElement:ElementRef;
  @ViewChild('characterIcon') characterIcon: ElementRef;
  @Output() nextClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() timerPaused: boolean = false;


  constructor(
    private animations: GameAnimationsService, 
    private ngZone: NgZone, 
    private wordings: WordingsService,
    private userData: UserDataService,
    ) { }

  ngAfterViewInit() {
    const scenarioIndex = this.userData.data.indexScenario;
    const characterName = this.wordings.translate("ui.chap" + scenarioIndex + ".character");
    this.characterIcon.nativeElement.style['background-image'] = 'url("assets/dynamic/persos/icon_' + characterName + '.png")';
  }

  clickNext(): void
  {
    this.nextClicked.emit();
    this.blockInput = true;
    this.ngZone.run(() => {
      this.mainElement.nativeElement.style.pointerEvents = 'none';
    });
  }

  updateDisplay(answers: string[], talkers: string[], textLines: TextLineData[])
  {
    if(this.currentVisibility)
    {
      let timeline = new TimelineMax();
      timeline.set(this.mainElement.nativeElement, {'pointer-events': 'none'});
      timeline.to(this.mainElement.nativeElement, {'opacity': 0, duration: 0.2});
      this.animations.pushAnimation("disappear bubble", timeline, 0, 0);
    }
    let targetVisibility = false;
    this.targetVisibility = false;
    let hasTimer = false;

    if(answers.length == 1)
    {
      targetVisibility = true;
      this.targetText = answers[0];
    }
    
    if(targetVisibility)
    {
      let timeline = new TimelineMax();
      timeline.call(() => {
        this.ngZone.run(() => {
          this.blockInput = true;
          this.currentVisibility = targetVisibility;
          this.mainElement.nativeElement.style.pointerEvents = 'all';
        });
      });
      timeline.call(() => {
        this.ngZone.run(() => {
          this.currentVisibility = this.targetVisibility;
          this.currentText = this.targetText;
        });
      });
      timeline.to(this.mainElement.nativeElement, {'opacity': 1, duration: 0.2});
      this.animations.pushAnimation("appear footer", timeline, 2, 20);
    }
    this.targetVisibility = targetVisibility;
  }
}
