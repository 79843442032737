import { query, transition, trigger, group, useAnimation, animate, animation, animateChild, style, stagger, sequence } from '@angular/animations';
import { dsl } from './app.animations';
import { Easing } from '../shared/animation/animation.interface';



/*
decorator :
animations: [xAnimations],
declarations :
@HostBinding('@animations') private animations;

composition :
query('.dsl_x', group([
	dsl.zoomIn(0, {scaleStart: 2}),
])),
*/

export const menuAnimation = [
	
  trigger('animations', [
		
    transition(':enter', group([
			 
			query('.dsl_bg', dsl.reset().fadeIn(0.0)),
			query('.dsl_fade', dsl.fadeIn(0.7)),
			query('.dsl_zoomx', dsl.zoomIn(0.25, {zoomX:true})),
			...dsl.sequenceLoop('.dsl_container > *:nth-child({{i1}})', 8, dsl.fadeIn, 0.1, 0.35),
			
		])),
		
		transition(':leave', group([
			
			query('.dsl_fadeout', dsl.reset().fadeOut()),
			query('.dsl_bg', dsl.fadeOut(0.3)),
			
			
		])),
		
  ])
];



export const confirmquitAnimation = [
	
  trigger('animations', [
		
    transition(':enter', group([
			 
			query('.dsl_bg', dsl.reset().fadeIn(0.0)),
			query('.dsl_fade', dsl.fadeIn(0.4)),
			query('.dsl_fade2', dsl.fadeIn(0.0)),
			query('.dsl_zoomx', dsl.zoomIn(0.25, {zoomX:true})),
			query('.dsl_up1', dsl.slideIn(0.2, {distanceIn:50, timeIn: 0.4})),
			query('.dsl_up2', dsl.slideIn(0.1, {distanceIn:50, timeIn: 0.4})),
			
		])),
		
		transition(':leave', group([
			
			query('.dsl_fadeout', dsl.reset().fadeOut()),
			query('.dsl_bg', dsl.fadeOut(0.3)),
			
			
		])),
		
  ])
];




export const modalAnimation = [
	
  trigger('animations', [
		
    transition(':enter', group([
			 
			query('.dsl_zoom', dsl.reset().zoomIn(0.0, {scaleStart: 0.9, timeIn:0.3})),
			
		])),
		
		transition(':leave', group([
			
			query('.dsl_zoom', dsl.reset().zoomOut(0.0, {scaleEnd: 0.8, timeOut:0.17, easeOut:Easing.EASE_OUT})),
		])),
		
  ])
];


