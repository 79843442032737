import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation, Output, Input } from '@angular/core';
import videojs from 'video.js';
import { WordingsService } from '../../../../services/wordings.service';
import { UserDataService } from '../../../../services/user-data.service';

export enum InfoType {
  Text,
  Video,
  Image
};

@Component({
  selector: 'app-info-element',
  template: `<div class="content-text" *ngIf="type == \'text\'" [innerHtml]="content"></div>
  <div class="content-img" *ngIf="type == 'image'" class="content-img" [ngStyle]="{'background-image': imgSrc()}"></div>
  <video class="content-video" *ngIf="type=='video'" class="video-player" controls="" autoplay="" name="media" [innerHtml]="videoSrc()"></video>`,
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupElement implements OnInit {
  constructor(
    private wordings: WordingsService
  ) { }
  
  @Input() content: string;
  @Input() type: string;

  ngOnInit() {
    
  }

  videoSrc(): string {
    return '<source src="assets/videos/' + this.content + '" type="video/mp4">';
  }

  imgSrc(): string {
    //console.log(this.content);
    return 'url(assets/images/pages/info_point/' + this.content + ')';
  }
}

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent implements OnInit {
  constructor(
    private userData: UserDataService,
    private wordings: WordingsService
  ) { }

  private showVideo: boolean = false;
  private version: string = 'formation';
  private elementsData: any[] = [];

  @Output() onClose = new EventEmitter();  
  @Output() onLinkClicked = new EventEmitter();

  ngOnInit() {
    const infoIndex : number = this.userData.data.dataActions['idinfo'];
    let infoIndexString : string = (infoIndex < 10 ? '0':'') + infoIndex;
    //console.log('show info popup ' + infoIndexString);
    this.showVideo = false;
    const videoKey = this.version + '_pointinfo.' + infoIndexString + '.video';
    this.showVideo = this.wordings.hasKey(videoKey);
    
    const wordingPrefix = 'pointinfo.' + infoIndexString + '.';
    const layout: string[] = this.wordings.translate(wordingPrefix + 'layout').split(' ');
    for(let infoType of layout)
    {
      let newElement = {content: this.wordings.translate(wordingPrefix + infoType)};
      if(infoType.startsWith("image"))
          newElement['type'] = 'image';
      else if(infoType.startsWith("text"))
        newElement['type'] = 'text';
      else if(infoType.startsWith("video"))
        newElement['type'] = 'video';
      this.elementsData.push(newElement);
    }
    
  }

  ngOnDestroy() {
    
  }

  backButtonPressed() {
    this.onClose.emit();
  }
}
