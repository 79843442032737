import { WordingsService } from "../services/wordings.service";

export interface Company{
	
	uid:string,
	title:string,
	endBtnText:string,
	endBtnLink:string,
	endText:string,
	logoUrl:string,
};

export function setDefaultValues(company: Company, wordings: WordingsService)
{
	if(company.endBtnText == '')
	{
		company.endBtnText = wordings.translate('ui.company.button.text');
	}
	if(company.endBtnLink == '')
	{
		company.endBtnLink = wordings.translate('ui.company.button.link');
	}
	if(company.endText == '')
	{
		company.endText = wordings.translate('ui.company.end');
	}
	if(company.logoUrl == '')
	{
		company.logoUrl = wordings.translate('ui.company.logo');
	}
}