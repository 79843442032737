import { Component, OnInit, HostBinding, ViewChild, ElementRef, ApplicationRef, Output, EventEmitter, NgZone, AfterViewInit } from '@angular/core';
import { creditsAnimation } from 'src/app/animations/credits.animations';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { InfoScrollService } from 'src/app/shared/ui/info-scroll.service';
import * as $ from 'jquery';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { GoogleAnalyticsService } from 'src/app/shared/api/google-analytics.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { TimelineMax } from 'gsap';
import { UserData } from 'src/app/model/userdata.interface';
import { UserDataService } from 'src/app/services/user-data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { GameService } from 'src/app/services/game.service';

declare var tarteaucitron: any;


@Component({
  selector: 'app-synthesis',
  templateUrl: './synthesis.component.html',
	styleUrls: ['./synthesis.component.scss'],
})
export class SynthesisComponent implements OnInit, AfterViewInit {
	
	@ViewChild('descElmt') descElmt:ElementRef;
	@ViewChild('mainContainer') mainContainer:ElementRef;
	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();
	
	title:string;
	desc:string;
	
	infoScroll:InfoScrollService;

  constructor(
		
		private ds:DataService,
		private wordings: WordingsService,
		private app:ApplicationRef,
		private storage:StorageService,
		private ga:GoogleAnalyticsService,
		private ngZone: NgZone,
		private userData: UserDataService,
		private modalService: ModalsService,
		private gameService: GameService,
	) { }

  ngOnInit() {
	this.title = this.wordings.translate('ui.chap' + this.userData.data.indexScenario + '.conclusion.title');
	this.desc = this.wordings.translate('ui.chap' + this.userData.data.indexScenario + '.conclusion.text');
  }

  ngAfterViewInit() {
	this.gameService.hideBackgroundWithFade();
	new TimelineMax().fromTo(this.mainContainer.nativeElement, {opacity: 0}, {opacity: 1, duration: 0.5});
	}
	
	public scroll(delta:number, distance:number):void
	{
		let elmt = this.descElmt.nativeElement;
		$(elmt).animate({
			scrollTop: elmt.scrollTop + delta * distance,
		}, 230);
	}

	clickContinue():void
	{
		this.modalService.open('chapter-end');
	}
	
}
