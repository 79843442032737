import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-end-game',
  templateUrl: './end-game.component.html',
	styleUrls: ['./end-game.component.scss'],
})
export class EndGameComponent implements OnInit {

	private isDefaultVersion: boolean;

	constructor(
		private modalService:ModalsService,
		private ds:DataService,
		private userData:UserDataService,
		private wordings: WordingsService,
		private api: ApiService,
	) { }

	ngOnInit() {
		this.userData.resetAll();
		this.userData.save();
		this.isDefaultVersion = this.ds.uid == null || this.ds.uid == this.api.defaultUID;
	}

	restart():void
	{
		this.userData.resetAll();
		this.userData.save();
		this.modalService.open('home');
	}
}
