import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	
	defaultUID:string = 'upwjtUqBLS3FDo0wL79Ns8mTQVGxU3ql';
	

	constructor(
		private http:HttpClient,
		private ds: DataService,
	) { }
	
	
	private getRand():string
	{
		return ''+Math.round(Math.random() * 9999999);
	}
	
	
	public getChapter(index:number):Observable<any>
	{
		return this.http.get('assets/json/chapter-test.json');
		
	}
	
	getParams():Observable<any>
	{
		return this.http.get('assets/json/params.json');
	}
	
	getData(uid:string = null):Observable<any>
	{
		/*if(environment.mockApi){
			return this.http.get('assets/mock/getdata.mock.json');
		}*/
		if(!uid) uid = this.defaultUID;
		let body:any = {companyUid : uid};
		return this.http.post(environment.BASE_URL + '/getData?rnd='+this.getRand(), body);
	}

	registerUser(uid: string = this.defaultUID)
	{
		if(!uid) uid = this.defaultUID;
		let body:any = {companyUid : uid};
		return this.http.post(environment.BASE_URL + '/registerUser', body);
	}
	
	getPreloadList(uid:string = null):Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		let body:any = {companyUid : uid};
		return this.http.post(environment.BASE_URL + '/getPreloadList', body);
	}
	
	getLeaderboard(uid:string):Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		let headers:any = {};
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		
		let body:any = {companyUid : uid};
		return this.http.post(environment.BASE_URL + '/getLeaderboard?rnd='+this.getRand(), body);
	}
	
	getIntermediateLeaderboard(uid:string, score:number, type:number):Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		let headers:any = {};
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		
		let body:any = {
			companyUid : uid,
			score : score,
			scoreType : type,
		};
		return this.http.post(environment.BASE_URL + '/getIntermediateLeaderboard?rnd='+this.getRand(), body);
	}
	
	saveScore(nickname:string, score:number, scores:number[], uid:string, additionalData1: string, additionalData2: string):Observable<any>
	{
		let sentScores = [];
		for(let i=0; i<this.ds.scenarios.length - 2; i++)
		{
			let score = 0;
			for(let j=0; j<=i; j++)
			{
				score += scores[j];
			}
			sentScores.push(score);
		}
		
		if(!uid) uid = this.defaultUID;
		let body:any = {
			companyUid : uid,
			nickname,
			score,
			additional_data_1: additionalData1,
			additional_data_2: additionalData2,
			intermediateScores: JSON.stringify(sentScores),//json
		};
		return this.http.post(environment.BASE_URL + '/saveScore', body);
	}

	login(login:string, password:string):Observable<any>
	{
		let body:any = {
			login,
			password,
		};
		return this.http.post(environment.BASE_URL + '/login', body);
	}
}
