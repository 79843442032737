import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WordingsService {
    selectedLanguage = 0;
    languages: string[];

    keys: any[];

    selectLanguage(languageIndex: number): void {
        this.selectedLanguage = languageIndex;
    }

    loadWordings(data: any): void {
        //console.log("Loading wordings...");
        this.languages = [];
        this.keys = [];
        for (var language of data) {
            var languageIndex = this.languages.indexOf(language.language);
            if (languageIndex < 0) {
                languageIndex = this.languages.push(language.language) - 1;
                this.keys.push([]);
            }


            for (let key in language.keys) {
                //console.log(key);
                this.keys[languageIndex][key] = language.keys[key];
            }
        }
        //console.log(this.keys);
        //console.log(this.translate('ui.game.title'));
    }

    translate(key: string): string {
        if(this.keys === undefined)
            return "";
        if(this.keys[this.selectedLanguage][key] != null)
            return this.keys[this.selectedLanguage][key];
        else return 'Error : Missing key ' + key + ' in wording';
    }

    hasKey(key: string): boolean {
        return this.keys[this.selectedLanguage][key] != null;
    }

    setTranslation(key: string, value: string) {
        this.keys[this.selectedLanguage][key] = value;
    }

    parseInfoLinks(text: string): any[] {
        const regex = /<(.+)>/;//<([\w\s-]+)>/;
        let result = [];
        let toParse = text;
        //console.log(text);
        while (toParse) {
            const parsed = toParse.match(regex);
            //console.log(parsed);
            if (parsed) {
                result.push({ type: 'normal', 'text': toParse.substr(0, parsed['index']) });
                result.push({ type: 'link', 'text': parsed[1] });
                toParse = toParse.substr(parsed['index'] + parsed[0].length);
            }
            else {
                result.push({ type: 'normal', 'text': toParse });
                toParse = null;
            }
        }
        return result;
    }
}