import { Component, OnInit, HostBinding, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { environment } from 'src/environments/environment';
import { landingAnimation } from 'src/app/animations/landing.animations';
import { TimeoutService } from 'src/app/shared/time/timeout.service';
import { fxAnimations } from 'src/app/animations/fx.animations';
import { WordingsService } from 'src/app/services/wordings.service';
import { TimelineMax } from 'gsap';
import { GameService } from 'src/app/services/game.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements AfterViewInit {
	
	@ViewChild('container') container: ElementRef;
	@ViewChild('image') image: ElementRef;
	
	prefix:string;

  constructor(
		private ds:DataService,
		private wordings: WordingsService,
		private modalService:ModalsService,
		private timeout:TimeoutService,
		private ngZone: NgZone,
		private gameService: GameService
	) { }
	
	
  	ngAfterViewInit() {
		
		let timeline = new TimelineMax();
		timeline.fromTo(this.container.nativeElement, {opacity: 0}, {opacity: 1, duration: 1});
		timeline.to(this.container.nativeElement, {opacity: 0, duration: 1}, "+=2");
		timeline.call(() => this.ngZone.run(() => this.modalService.open('home')));
		this.image.nativeElement.style['background-image'] = "url(" + this.ds.company["logoUrl"] + ")";
	}
}
